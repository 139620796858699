/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import { DefaultButton, Stack, StackItem } from "office-ui-fabric-react";
import Barcode from "react-barcode";
import { doFormatTitle } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import React from "react";
import PropTypes from "prop-types";
import {
  abhyasStage,
  NEWPRACTITIONER,
  PRACTITIONER,
  SEEKER,
  TRAINER,
} from "sites-common/utils/validations";
import { compact } from "sites-common/utils/lodash";

const colors = {
  shade1: "#ffe755",
  shade2: "#871282",
  shade3: "#f9ff97",
  shade4: "#ffa535",
  shade5: "#ffebad",
};

const Card = (props) => (
  <div
    sx={{ boxShadow: "0px 3px 6px #00000029", bg: "white", mx: 2, mt: 3, p: 3 }}
    {...props}
  />
);

const age_group = (age) => {
  let age_map = "76-99";
  if (age < 6) {
    age_map = "1-5";
  } else if (age < 16) {
    age_map = "6-15";
  } else if (age < 26) {
    age_map = "16-25";
  } else if (age < 36) {
    age_map = "26-35";
  } else if (age < 46) {
    age_map = "36-45";
  } else if (age < 56) {
    age_map = "46-55";
  } else if (age < 66) {
    age_map = "56-65";
  } else if (age < 76) {
    age_map = "66-75";
  }
  return age_map;
};

const maskemail = (x) =>
  x &&
  x.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, "*") + c);
const maskphone = (x) =>
  x &&
  x.replace(/^(.....)(.*)(..)$/, (_, a, b, c) => a + b.replace(/./g, "*") + c);

function IDCard1({ srcmProfile: item }) {
  const aStage = abhyasStage(item?.record_type, item?.abhyasiId);
  if (!aStage) return null;

  return (
    <React.Fragment>
      <Card>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="top">
          <Stack verticalAlign="space-between">
            <StackItem>
              <Text sx={{ fontSize: "1.2em" }}>
                {doFormatTitle(item?.name || "")}{" "}
              </Text>
              <br />
              <Text
                sx={{ color: item?.gender === "M" ? "darkblue" : "purple" }}
              >
                {" "}
                {item?.gender} {age_group(item?.age)}
              </Text>
            </StackItem>
          </Stack>

          {aStage !== SEEKER && (
            <StackItem>
              <Stack tokens={{ childrenGap: 4 }} horizontalAlign="center">
                <Barcode
                  margin={0}
                  value={item?.abhyasiId}
                  height={50}
                  width={1}
                />
                <Text variant="printFormLabel">
                  Heartfulness {aStage === TRAINER && "Trainer"}
                  {aStage === PRACTITIONER && ""}
                  {aStage === NEWPRACTITIONER && "Welcome"} ID{" "}
                </Text>
              </Stack>
            </StackItem>
          )}
          {aStage === SEEKER && (
            <DefaultButton
              href="/profile"
              iconProps={{ iconName: "AsteriskSolid" }}
              text="Register as Practitioner"
            />
          )}
        </Stack>
      </Card>

      <Card>
        <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
          {[
            [
              ["Sign-In Mobile", maskphone(item?.phone)],
              ["Sign-In Email", maskemail(item?.email)],
            ],
            [
              ["Heartfulness Center", item?.srcm_group],
              ["Current Preceptor", item?.current_preceptor],
            ],
            [
              ["Citizenship", item?.citizen],
              [
                "City",
                item?.city &&
                  compact([item?.city, item?.state || item?.country]).join(
                    ", "
                  ),
              ],
            ],
          ].map((g) => (
            <Stack sx={{ mb: 2 }} horizontal wrap tokens={{ childrenGap: 10 }}>
              {g.map(([k, v]) => (
                <Stack>
                  <Text variant="printFormLabel">{k}</Text>

                  <Text variant="printFormValue">
                    {v || <span sx={{ bg: colors.shade5 }}>--Missing--</span>}
                  </Text>
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack>

        <DefaultButton
          sx={{ mt: 3 }}
          text="Update Details"
          title="Update Details"
          iconProps={{ iconName: "Edit" }}
          href="/profile"
        />
      </Card>
    </React.Fragment>
  );
}

IDCard1.propTypes = {
  srcmProfile: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    current_preceptor: PropTypes.string,
    age: PropTypes.number,
    abhyasiId: PropTypes.string,
    state: PropTypes.string,
    gender: PropTypes.string,
    record_type: PropTypes.string,
    email: PropTypes.string,
    srcm_group: PropTypes.string,
    citizen: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};
IDCard1.defaultProps = {};
export default IDCard1;
