/** @jsx jsx */
import { jsx, Text, Image, Card } from "theme-ui";
import { DefaultButton, Stack, StackItem } from "office-ui-fabric-react";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import HFNLogo from "../../assets/hfn1.jpg";
import Kanha from "../../assets/kanha.jpg";

const C = (props) => (
  <Card
    sx={{
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "2px",
      border: "2px solid ",
      borderColor: "background",
      backgroundColor: "background",
      p: 2,
      maxWidth: "600px",
    }}
    {...props}
  />
);

const CImage = (props) => (
  <Image alt="-" sx={{ height: "100px", width: "100px" }} {...props} />
);

const loggedInMenuItems = [
  {
    FreeFlowContent: () => (
      <C>
        <Text variant="title">My Profile</Text>
        <Stack
          horizontal
          horizontalAlign="space-evenly"
          tokens={{ padding: 10, childrenGap: 20 }}
        >
          <StackItem sx={{ width: "100px" }}>
            <CImage src={HFNLogo} />
          </StackItem>
          <Stack tokens={{ childrenGap: 5 }}>
            <DefaultButton href="/profile" text="View / Update Profile" />

            <DefaultButton href="/account/password" text="Change Password" />
            <DefaultButton href="/subscriptions" text="Subscriptions" />
          </Stack>
        </Stack>
      </C>
    ),
  },

  {
    FreeFlowContent: () => {
      const { srcmProfile } = useAuth();
      const isAtleastPractitioner = srcmProfile?.isAtleastPractitioner;

      return (
        <C>
          <div>
            <a
              target="_blank"
              href="https://heartfulness.org/kanha"
              rel="noreferrer"
            >
              <Image src={Kanha} />
            </a>
            <Stack horizontal horizontalAlign="space-evenly">
              <DefaultButton
                target="_blank"
                href="https://heartfulness.org/kanha/my-registrations"
                text="My Registrations"
              />
              {isAtleastPractitioner && (
                <DefaultButton
                  href="/account/overseas-visitor-documents"
                  text="Overseas Visitors"
                />
              )}
            </Stack>
          </div>
        </C>
      );
    },
  },

  // {
  //   label: "Recurring Donations",
  //   subLabel: "See My Donations >>",
  //   to: "/subscriptions",
  //   icon: HFNPlaceHolder,
  //   key: "ER",
  // },
  // {
  //   label: "Registrations",
  //   subLabel: "Update Event Registrations >>",
  //   to: "/subscriptions",
  //   icon: HFNPlaceHolder,
  //   key: "5R",
  // },
  // {
  //   label: "Bookings",
  //   subLabel: "See My Ashram Visit >>",
  //   to: "/subscriptions",
  //   icon: HFNPlaceHolder,
  //   key: "5B",
  // },
];

export default loggedInMenuItems;
