/** @jsx jsx */
import { Card, jsx, Text } from "theme-ui";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";

import { ActionButton, DefaultButton, Stack } from "office-ui-fabric-react";
import { useSelector } from "react-redux";
import { selectorGlobalConfig } from "gatsby-plugin-hfn-profile/state/selectors";
import IDCard1 from "gatsby-plugin-hfn-profile/components/IDCard1";
import { abhyasStage, SEEKER } from "sites-common/utils/validations";
import DefaultLayout from "../layouts/dlw-mobile";

import loggedInMenuItems from "../components/MenuItems/abhyasiItems";
import MenuItems from "../components/MenuItems";

import config from "../../gatsby-apps-config";
import LevelBadge from "../components/ProfileWidgetCore/LevelBadge1";
import SeekerNotice from "../components/ProfileWidgetCore/SeekerNotice";
import FunctionaryHub from "../components/FunctionaryHub";

const levelItems = (abhyas_stage) => [
  {
    FreeFlowContent: () => (
      <Card
        sx={{
          boxShadow: "0px 3px 6px #00000029",
          opacity: 1,
          borderRadius: "2px",
          borderColor: "background",
          backgroundColor: "background",
        }}
      >
        <LevelBadge bg="beige" abhyas_stage={abhyas_stage} />
      </Card>
    ),
  },
];

const functionaryItems = () => [
  {
    FreeFlowContent: () => <FunctionaryHub />,
  },
];

const Index = (/* { data } */) => {
  // const { allXPage } = data;
  const { tosUrl, privacyPolicyUrl } = useSelector(selectorGlobalConfig);

  const { srcmProfile, isLoggedIn } = useAuth();

  const aItems = Object.entries(config).filter(
    ([, r]) => !!r.onHome && r.config.accessControl.conditions(srcmProfile)
  );
  const mLinks = aItems.filter(([, r]) => r.onHome === "link");
  const mItems = aItems.filter(([, r]) => r.onHome === "items");

  const aStage = abhyasStage(srcmProfile?.record_type, srcmProfile?.abhyasiId);
  return (
    <div>
      <DefaultLayout bg="#E4D2BA">
        {aStage === SEEKER && <SeekerNotice />}

        {aStage && (
          <div sx={{ my: 2 }}>
            <IDCard1 srcmProfile={srcmProfile} />
          </div>
        )}

        <MenuItems items={levelItems(aStage)} />

        {srcmProfile?.hasPositions && <MenuItems items={functionaryItems()} />}

        {mItems.length > 0 &&
          mItems.map(([, mi]) => (
            <div>
              {/* <div sx={{ pt: 2, textAlign: "center" }}>
              <Text variant="header">{mi.linkText}</Text>
            </div> */}
              <MenuItems items={mi.menuItems} />
            </div>
          ))}
        {isLoggedIn && (
          <div>
            <MenuItems items={loggedInMenuItems} />
          </div>
        )}

        {mLinks.length > 0 && (
          <div sx={{ mt: 4 }}>
            <div sx={{ pt: 2, textAlign: "center" }}>
              <Text variant="header">My Applications</Text>
            </div>
            {mLinks.map(([link, ml]) => (
              <DefaultButton
                target="_blank"
                sx={{ m: 2 }}
                href={link}
                text={ml.linkText}
              />
            ))}{" "}
          </div>
        )}

        <hr sx={{ mt: 4, mb: 1 }} />
        <Stack horizontal horizontalAlign="space-evenly">
          {/* <ActionButton href="/account/password/" text="Change Password" /> */}
          <ActionButton
            target="_blank"
            href={tosUrl?.in}
            text="Terms of Service"
          />
          <ActionButton
            target="_blank"
            href={privacyPolicyUrl?.in}
            text="Privacy Policy"
          />
        </Stack>
      </DefaultLayout>
    </div>
  );
};

export default Index;
