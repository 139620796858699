/** @jsx jsx */

import { jsx, Text } from "theme-ui";
import TimedAlert from "sites-common/components/ui/TimedAlert";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { SEEKER } from "sites-common/utils/validations";
import SignOutButton from "gatsby-plugin-hfn-profile/auth/SignOutButton";

function SeekerNotice() {
  const { srcmProfile } = useAuth();
  if (!srcmProfile) return null;
  return (
    <TimedAlert
      sx={{ mt: 2, mb: 4 }}
      displayIf={srcmProfile?.abhyasStage === SEEKER}
      time={30000}
      variant="notify"
    >
      <Text variant="printFormValue">
        You are currently signed as{" "}
        <b>{srcmProfile?.email || srcmProfile?.phone}</b>. If you already have a
        Heartfulness Id, please sign-out now and sign-in with your registered
        email id or mobile number.
        <br sx={{ mb: 2 }} />
        <SignOutButton btnText="Sign-in as another user" />
      </Text>
    </TimedAlert>
  );
}

export default SeekerNotice;
